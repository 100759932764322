
import React from 'react'

const Price = () => {
  return (
    <div className='PriceContainer'>
      <h2> Price</h2>
      <ul>
        <li>✅ class $75 ⏰1:00h</li>
        <li>✅ class $95 ⏰1:30h</li>
        <li>✅ $140 class ⏰2h</li>
        <li>🚗 car rental for the road test $95</li>
      </ul>

    </div>
  )
}

export default Price